.footer {

    padding: 8rem 15%;
    color: $white;
    background-color: $blue;

    h3 {

        margin-bottom: 0.6rem;

    }

    .site-index {

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 2rem;

        .index-name {

            margin-right: 3rem;

            @media (max-width: $tablet) {

                margin-right: 1.8rem;
        
            }

            img {

                width: 2.4rem;
                transition: 0.3s;

                &:hover {

                    transform: scale(1.2);
                    transition: 0.3s;

                }

                @media (max-width: $tablet) {

                    width: 2rem;
            
                }

            }

            @media (max-width: $tablet) {

                margin-bottom: 1.8rem;
        
            }

        }

    }

    .footer-bottom-text {

        padding-top: 4rem;

        @media (max-width: $tablet) {

            padding-top: 2rem;
    
        }

    }

    @media (max-width: $tablet) {

        padding: 4rem 15%;

    }

}
.button {

    font-size: 1.4rem;
    padding: 1.2rem 3rem;
    color: $white;
    margin-right: 2rem;
    border: 0.1rem solid;
    transition: 0.3s;
    cursor: pointer;
    border-radius: 0.3rem;

    &.primary {

        background-color: $blue;
        border-color: $blue;

        &:hover {

            color: $blue;
            background-color: $white;
            transition: 0.3s;

        }

    }

    &.secondary {

        background-color: $orange;
        border-color: $orange;

        &:hover {

            color: $orange;
            background-color: $white;
            transition: 0.3s;

        }

    }

    @media (max-width: $tablet) {

        padding: 0.6rem 2rem;
        font-size: 1rem;
        margin-right: 1.6rem;

    }

}
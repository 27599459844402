@font-face {
    font-family: 'bold';
    src: url('../assets/opensans-bold-webfont.woff2') format('woff2'),
         url('../assets/opensans-bold-webfont.woff') format('woff'),
         url('../assets/opensans-bold-webfont.ttf') format('truetype'),
         url('../assets/opensans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'light';
    src: url('../assets/opensans-light-webfont.woff2') format('woff2'),
         url('../assets/opensans-light-webfont.woff') format('woff'),
         url('../assets/opensans-light-webfont.ttf') format('truetype'),
         url('../assets/opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'regular';
    src: url('../assets/opensans-regular-webfont.woff2') format('woff2'),
         url('../assets/opensans-regular-webfont.woff') format('woff'),
         url('../assets/opensans-regular-webfont.ttf') format('truetype'),
         url('../assets/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'semibold';
    src: url('../assets/opensans-semibold-webfont.woff2') format('woff2'),
         url('../assets/opensans-semibold-webfont.woff') format('woff'),
         url('../assets/opensans-semibold-webfont.ttf') format('truetype'),
         url('../assets/opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}

body {

    font-family: 'light';

}

a {

    text-decoration: none;
    color: $blue;

}

h1 {

    font-family: 'bold';
    font-size: 4rem;
    margin-top: -10px;
    padding-bottom: 0.8rem;
    line-height: 4rem;

    @media (max-width: $tablet) {

        font-size: 3rem;
        line-height: 3rem;

    }

    @media (max-width: $mobile) {

        font-size: 2.6rem;
        line-height: 2.6rem;

    }

}


h3 {

    font-family: 'semibold';
    font-size: 1.8rem;
    line-height: 2.2rem;

}

h4 {

    font-family: 'semibold';
    font-size: 3rem;
    line-height: 3.4rem;

    @media (max-width: $tablet) {

        font-size: 2rem;
        line-height: 2.4rem;

    }

}

.thin-title {

    font-family: 'light';
    font-size: 4rem;

    @media (max-width: $tablet) {

        font-size: 3rem;

    }

    @media (max-width: $mobile) {

        font-size: 2.6rem;

    }

}

.button {

    font-family: 'semibold';

}

.side-text {

    letter-spacing: 2.2rem;
    font-size: 0.8rem;

    @media (max-width: $desktop-small) {

        letter-spacing: 1.6rem;

    }

    @media (max-width: $tablet) {

        letter-spacing: 2.2rem;

    }

    @media (max-width: $mobile) {

        font-size: 0.6rem;
        letter-spacing: 1.2rem;

    }


}

.hl-text {

    font-family: 'light';
    font-size: 3.4rem;
    line-height: 4.4rem;

    @media (max-width: $tablet) {

        font-size: 2.8rem;
        line-height: 3.8rem;

    }

    @media (max-width: $mobile) {

        font-size: 2rem;
        line-height: 3rem;

    }

}

.hl-subtext {

    font-family: 'light';
    font-size: 2rem;
    line-height: 3rem;

    @media (max-width: $tablet) {

        font-size: 1.8rem;
        line-height: 2.8rem;

    }

    @media (max-width: $mobile) {

        font-size: 1.2rem;
        line-height: 2.2rem;

    }

}

.bold {

    font-family: 'semibold';

}
.footer-text {

    font-family: 'light';
    font-size: 1.4rem;
    line-height: 2.2rem;

}

.index-name {

    font-family: 'semibold';
    font-size: 1.4rem;
    line-height: 2rem;

    @media (max-width: $tablet) {

        font-size: 0.8rem;

    }

}

.footer-bottom-text {

    font-family: 'light';
    font-size: 1rem;

}

.case-sub {

    font-family: 'light';
    font-size: 1.4rem;
    line-height: 1.2rem;
    text-transform: uppercase;

    @media (max-width: $tablet) {

        font-size: 0.8rem;
        line-height: 0.6rem;

    }

}

.case-text {

    font-family: "light";
    font-size: 1.4rem;
    line-height: 2.4rem;

    @media (max-width: $tablet) {

        font-size: 1.2rem;
        line-height: 2.2rem;

    }

}
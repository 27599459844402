.contact {

    padding: 8rem 0;

    .hl-text {

        padding-bottom: 2rem;

    }

}
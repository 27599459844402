.cases-container {

    .case-container {

        width: 100%;
        padding: 8rem 0;
        // height: 40rem;
        position: relative;

        .case-inner {

            z-index: 40;
            padding-left: 15%;
            width: 70%;
            padding-right: 15%;
            display: flex;
            align-items: center;
            height: 100%;

        }

        .case-background {

            position: absolute;
            left: 0;
            top: 0;
            width: 50%;
            height: 100%;
            z-index: -1;
            overflow: hidden;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            .case-background-overlay {

                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: rgb(0,133,255);
                background: linear-gradient(180deg, rgba(0,133,255,1) 0%, rgba(253,187,45,0) 100%);

            }

        }

        .case-foreground {

            position: absolute;
            right: 6rem;
            top: 0;
            width: 60%;
            height: 100%;
            background-color: $white;
            z-index: -1;
            transform: skew(15deg);

        }

        .case-foreground-bg {

            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $white;
            z-index: -2;

        }

        .case-background,
        .case-foreground {

            @media (max-width: $mobile) {

                display: none;
                visibility: hidden;
        
            }

        }

        
        .mockup-wrapper {


            width: 100%;
            overflow: hidden;

            img {

                width: 100%;

            }

            @media (max-width: $mobile) {

                margin-bottom: 2rem;
        
            }

        }

        .button-wrapper {

            display: flex;
            margin-top: 2rem;
    
        }

        &:nth-child(even) {

            .case-foreground {

                background-color: $grey;
                left: 6rem;
                right: unset;
                transform: skew(-15deg);

            }

            .case-foreground-bg {

                background-color: $grey;

            }

            .case-background {

                right: 0;
                left: unset;

                .case-background-overlay {

                    background: rgb(255,209,90);
                    background: linear-gradient(180deg, rgba(255,209,90,1) 0%, rgba(253,187,45,0) 100%);

                }

            }

            @media (max-width: $mobile) {

                .row {

                    flex-wrap: wrap-reverse;

                }
        
            }

        }

    }

}
.navigation {
    
    width: 100%;
    position: fixed;
    color: $white;
    font-size: 1.6rem;
    z-index: 99;
    text-shadow: 1px 1px $black;

    .navigation_inner {

        display: flex;
        justify-content: flex-end;
        padding: 4rem 0;
        background-color: transparent;
        position: relative;
        opacity: 0;
        animation: 600ms fadeIn 2000ms forwards;

        .navigation_menu {

            margin-right: 15rem;
            animation: 600ms fadeIn forwards;

            &.open {

                animation: 600ms fadeIn forwards;
    
            }
    
            span {
    
                margin-left: 10rem;
    
            }

        }


        @media (max-width: $mobile) {

            height: 50vh;
            justify-content: center;
            align-items: center;
            animation: 600ms fillHeightRev forwards;
            background-color: $blue;

            .navigation_menu {

                margin-right: 0;
                display: flex;
                flex-direction: column;
                
                span {

                    margin-left: 0;
                    padding: 2rem 0;
                    text-align: center;

                }
            }

            &.open {

                animation: 600ms fillHeight forwards;
    
            }
            
        }

    }

    .navigation_hamburger {

        width: 4rem;
        height: 2.5rem;
        position: absolute;
        cursor: pointer;
        animation: 600ms botToTop forwards;
        right: 5rem;
        z-index: 99;

        &.open {

            span {
    
                &:nth-child(1),
                &:nth-child(6) {
        
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
        
                }

                &:nth-child(2),
                &:nth-child(5) {
        
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
        
                }

                &:nth-child(1){

                    left: 4px;
                    top: 3px;

                }

                &:nth-child(2){

                    left: calc(50% - 4px);
                    top: 3px;
                    
                }

                &:nth-child(3){

                    left: -50%;
                    opacity: 0;
                    
                }

                &:nth-child(4){

                    left: 100%;
                    opacity: 0;
                    
                }

                &:nth-child(5){
                    
                    left: 4px;
                    top: 16px;
                    
                }

                &:nth-child(6){
                    
                    left: calc(50% - 4px);
                    top: 16px;
                    
                }

            }
    
        }

        &.change-color {


            span {

                background: $blue;

            }

        }
    
        span {
    
            display: block;
            position: absolute;
            height: 0.4rem;
            width: 50%;
            background: $white;
            opacity: 1;
            right: 10rem;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
    
            &:nth-child(even) {
    
                left: 50%;
    
            }
    
            &:nth-child(odd) {
    
                left: 0;
    
            }
    
            &:nth-child(1),
            &:nth-child(2) {
    
                top: 0;
    
            }
    
            &:nth-child(3),
            &:nth-child(4) {
    
                top: 0.9rem;
    
            }
    
            &:nth-child(5),
            &:nth-child(6) {
    
                top: 1.8rem;
    
            }
    
        }

        @media (max-width: $mobile) {

            top: 2.5rem;
            right: 2.5rem;
            visibility: visible;
            opacity: 1;
            animation: none;
            
        }
    
    }

    &.scrolled {

        .navigation_hamburger {

            animation: 600ms topToBot forwards;

            @media (max-width: $mobile) {

                animation: none;
                
            }

        }

        .navigation_menu {

            animation: 600ms fadeOut forwards;

            @media (max-width: $mobile) {

                animation: none;
                
            }

        }

    }

    a,
    .anchor-link {

        color: $white;

    }

}